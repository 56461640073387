import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import '@/assets/css/reset.css';
import { 
    // Tabbar, TabbarItem, Search, Swipe, SwipeItem, Area, Overlay, NavBar, Collapse, CollapseItem,  , Picker, ImagePreview, Uploader, Divider, Loading, 
    Overlay,Area,
List, Tab, Tabs, Popup, IndexBar, IndexAnchor, Checkbox, CheckboxGroup, Button, Dialog, Image as VanImage} from 'vant';






const app = createApp(App)

app.use(store).use(router).mount('#app')
app.use(Tab)
.use(Tabs)
.use(Popup)
.use(List)
.use(VanImage)
.use(IndexBar)
.use(IndexAnchor)
.use(Checkbox)
.use(CheckboxGroup)
.use(Button)
.use(Dialog)
// .use(Tabbar)
// .use(TabbarItem)
// .use(Search)
// .use(Swipe)
// .use(SwipeItem)
.use(Area)
.use(Overlay)
// .use(NavBar)
// .use(Collapse)
// .use(CollapseItem)
// .use(Picker)
// .use(ImagePreview)
// .use(Uploader)
// .use(Divider)
// .use(Loading)



