<template>
    <div>
        <van-overlay :show="show" @click="show = false" z-index="9999">
            <div class="wrapper" @click.stop>
                <van-area :title="title" :area-list="areaList" :columns-num="grade"  @confirm="confirmFn" @cancel="cancelFn"/>
            </div>
        </van-overlay>
    </div>
</template>
<script setup>
import { areaList } from '@vant/area-data';
import { ref, defineProps, defineEmits, watch } from 'vue';
console.log("areaList",areaList)
const props = defineProps({
    showOverlay: 0,
    grade: {
        type: Number,
        default: 2
    },
    title: {
        type: String,
        default: '切换城市'
    }
})
const emit = defineEmits(['getCity'])
const show = ref(false)
watch(
    () => props.showOverlay,
    (newVal, oldVal) => {
        show.value = true;
    }
)
const confirmFn = (val) => {
    let len = val.selectedOptions.length - 1;
    let text = val.selectedOptions;
    emit('getCity',text)
    show.value = false;
}
const cancelFn = () => {
    show.value = false;
}
</script>
<style scope lang="scss">
.wrapper{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
</style>

