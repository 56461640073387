<template>
  <div class="wrap">
    <!-- <div ref="mapContainer"></div> -->
    <div class="topBox">
      <div class="place" @click="openCitySelection">
        {{ city }}
      </div>
      <div class="row r1">
        <!-- <div class="place">南宁市星湖路39号大板二</div> -->
        <div v-show="cutEditionBtnState" class="cutEditionBtn" @click="cutEditionFn">切换身份</div>
      </div>
      <div class="row r2">
        <div class="ipt">
          <div class="icon"></div>
          <input type="text" placeholder="找劳务工人" disabled />
        </div>
      </div>
    </div>
    <main class="home">
      <div class="quick">
        <swiper
          :pagination="pagination2"
          :modules="modules2"
          :slides-per-view="5"
          :slidesPerGroup="5"
          :space-between="centerSpace"
          :grid="grid2"
          class="quickSwiper"
        >
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openMobileAtteView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon1.png" alt="" />
              </div>
              <div class="dd">考勤打卡</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(
                  1,
                  'bridge:/baseWorker/openEntraceInviteView/pageInit'
                )
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon2.png" alt="" />
              </div>
              <div class="dd">入场邀请</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon3.png" alt="" />
              </div>
              <div class="dd">招工找活</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openSafeTrainView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon4.png" alt="" />
              </div>
              <div class="dd">安全培训</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openSalaryCardView/pageInit')
              "
            >
              <div class="note" v-if="typeKa">{{ typeKa }}</div>
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon5.png" alt="" />
              </div>
              <div class="dd">工资绑卡</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openMyContractView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon6.png" alt="" />
              </div>
              <div class="dd">电子合同</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openAtteView/pageInit')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon7.png" alt="" />
              </div>
              <div class="dd">考勤查询</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="a" 
            @click="loginJump(1, 'bridge:/webview/open/pageInit?url=https://huajiantong.com/h5Mall/pages/passport/authlogin?pageName=healthServer')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/zoologyIconFw.png" alt="" />
              </div>
              <div class="dd">体检服务</div>
            </div>
          </swiper-slide>
          <!-- <swiper-slide>
            <div class="a" @click="toSTZQ">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon8.png" alt="" />
              </div>
              <div class="dd">生态专区</div>
            </div>
          </swiper-slide> -->
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openWagesCheckView/pageInit')
              "
            >
              <div class="note" v-if="monthPayroll">{{ monthPayroll }}</div>
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon9.png" alt="" />
              </div>
              <div class="dd">工资查询</div>
            </div>
          </swiper-slide>
          <!-- <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openWorkmateView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon10.png" alt="" />
              </div>
              <div class="dd">工友圈子</div>
            </div>
          </swiper-slide> -->
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openMyLogTeamView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon11.png" alt="" />
              </div>
              <div class="dd">班组记工</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openMyOnTeamView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon12.png" alt="" />
              </div>
              <div class="dd">班组登记</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openTeamPayrollView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon13.png" alt="" />
              </div>
              <div class="dd">班组工资</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="a" @click="loginJump(0, '/personalExcellentScore')">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon14.png" alt="" />
              </div>
              <div class="dd">华建优分</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="a" @click="seeAmovie">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon15.png" alt="" />
              </div>
              <div class="dd">一起追剧</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(1, 'bridge:/baseWorker/openMyComplaintView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon16.png" alt="" />
              </div>
              <div class="dd">我的投诉</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="
                loginJump(
                  1,
                  'bridge:/baseWorker/openWorkerCommitmentView/pageInit'
                )
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon17.png" alt="" />
              </div>
              <div class="dd">进退承诺</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <Swiper
        :pagination="pagination"
        :autoplay="autoplay"
        :slides-per-view="1"
        :modules="modules"
        :loop="loop"
        class="banner"
        @slideChange="oNslideChange"
      >
        <swiper-slide>
          <img src="~@/assets/images/homeBan1.jpg" alt="" />
        </swiper-slide>
      </Swiper>

      <section class="projectColumn">
        <van-tabs
          class="border"
          color="#f70000"
          line-width="56"
          title-inactive-color="#2d322d"
          swipe-threshold="4"
          v-model:active="active"
        >
          <!-- <van-tab title="劳务金融"></van-tab>
            <van-tab title="招工信息"></van-tab> -->
          <van-tab title="培训课程"></van-tab>
          <van-tab title="热门短剧"></van-tab>
        </van-tabs>
        <div class="projectList">
          <!-- <div class="item sty1" v-if="active === 0">
              <div class="li">
                <h3>劳务工资贷</h3>
                <p>单笔借支额度：每个工人单笔贷款额度不超5000（每月一次）</p>
                <div class="hint">
                  借款要求：劳务公司质押产值单、合同等相关资料，项目有使用一号工班劳务管理系统
                </div>
                <div class="bom">
                  <div class="source">合作的金融机构：<em>鹏金所</em></div>
                  <div class="num">
                    已服务客户数
                    <em>5000</em>
                    <span>+</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="item sty2" v-if="active === 1">
              <div class="li">
                <h3>抹灰工人每天240元</h3>
                <div class="price"><em>500</em>元/天</div>
                <p>河北雄安，用力工跟抹灰的干活，240一天用力工跟抹灰的干活240一天</p>
                <div class="bom">
                  <div class="time">2024/03/16&nbsp;&nbsp;&nbsp;15:18</div>
                  <div class="site">乌鲁木齐</div>
                </div>
              </div>
              <div class="li">
                <h3>水暖/地暖工要3-10人</h3>
                <div class="price"><em>面议</em></div>
                <div class="label">
                  <div class="i">包吃</div>
                  <div class="i">包住</div>
                  <div class="i i2">3-10人</div>
                </div>
                <p>内蒙乌海需要水暖工四名</p>
                <div class="bom">
                  <div class="time">2024/03/16&nbsp;&nbsp;&nbsp;15:18</div>
                  <div class="site">吴忠-盐池</div>
                </div>
              </div>
            </div> -->
          <div class="item sty3" v-show="active === 0">
            <div
              class="li"
              v-for="item in peiXunList"
              :key="item.gangWeiName"
              @click="toOutsideChain(item.detailUrl)"
            >
              <div class="pic">
                <img :src="item.fengMian" alt="" />
              </div>
              <div class="txt">
                <h4 v-html="item.name"></h4>
                <div class="price">
                  <div class="num"><em>¥</em>{{ item.price }}</div>
                  <div class="label" v-html="item.gangWeiName"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="item sty4" v-show="active === 1">
            <div
              class="li"
              @click="toOutsideChain(item.link)"
              v-for="item in duanJuList"
              :key="item.title"
            >
              <div class="dt">
                <img :src="item.hcoverImg" alt="" />
                <div class="btn"></div>
              </div>
              <div class="dd">
                <h4 v-html="item.title"></h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="lend">
        <div class="hTil">最新借贷动态</div>
        <Swiper
          :autoplay="autoplay"
          :slides-per-view="1.2"
          :loop="loop"
          :space-between="15"
          class="lendSwiper"
          @slideChange="oNslideChange"
        >
          <swiper-slide>
            <img src="~@/assets/images/home/homeLendPic1.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="~@/assets/images/home/homeLendPic2.png" alt="" />
          </swiper-slide>
        </Swiper>
      </section>

      <section class="lump commodity">
        <div class="hTil">
          <em>热销商品</em>
          <!-- <div class="more">我的偏好</div> -->
        </div>
        <Waterfall :listData="[duanJuData,peiXunData]"></Waterfall>
      </section>
    </main>

    <!-- 选择城市 -->
    <city-selection
      :showOverlay="isCitySelectionOpen"
      @getCity="setCity"
    ></city-selection>
  
  </div>
</template>
<script setup>
import Waterfall from "@/components/Waterfall.vue";
import { Pagination, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import CitySelection from "@/components/CitySelection.vue";
import { showDialog } from "vant";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/grid";
import { getRecommend, getPeiXunCenterV2Recommend,getCityByIp } from "@/api/http";
import { ref, onMounted } from "vue";
import { useRouter,useRoute } from "vue-router";
import { number } from "echarts";


const { query } = useRoute();




const duanJuList = ref([]);
const peiXunList = ref([]);

const duanJuData = ref([]);
const peiXunData = ref([]);

// 热门短剧
const getRecommendApi = async () => {
  const { data } = await getRecommend();
  duanJuData.value = data.data.list;
  duanJuList.value = getRandomList( [...data.data.list],3).tuList;
};

// 培训课程
const getPeiXunCenterV2RecommendApi = async () => {
  const {data} = await getPeiXunCenterV2Recommend();
  peiXunData.value = data.data;
  peiXunList.value = getRandomList( [...data.data],4).tuList;
};


// 随机提取一次
function randomExtraction (arr){
  let max = arr.length;
  var n = Math.floor((Math.random() * max)); // 随机数 
  let newArr = arr;
  let tu = newArr.splice(n, 1);

  return {
    // n,
    tu, // 提取出来的
    newArr , // 被提取后的数组
  }
}


// 随机提取固定数量
function getRandomList(arr,max){
  let arrList= arr;
  let list = [];
  for(let i = 0;i<=max-1;i++){
    let v = randomExtraction(arrList);
    arrList =  v.newArr;
    list.push(...v.tu)
  }
 return{
    list: arrList,
    tuList: list
 }
}

onMounted(() => {
  
  getRecommendApi();
  getPeiXunCenterV2RecommendApi();
});

const router = useRouter();

const swiperAct = ref(0);
const modules = [Pagination];
const pagination = {
  clickable: true,
};
const leftSpace = ref(0);
const centerSpace = ref(0);
const modules2 = [Pagination, Grid];
const pagination2 = {
  clickable: true,
};
const grid2 = {
  fill: "column",
  rows: 2,
};
const show = ref(false);
const newPraiseList = ref([2]);
const registerState = ref(false); //用户是否登录，本地测试用true，上线用false;
const prestoreId = ref(-1);
const prestoreUrl = ref("");
const showPop = ref(false);
const typeKa = ref("未绑定");
const monthPayroll = ref("5月未发");

// 切换到企业端
const cutEditionFn = () => {
  window.location.href = "bridge:/base/changeAppSystem/pageInit?type=2";
};

const newPraiseToggle = (num) => {
  let isExist = newPraiseList.value.includes(num);
  if (isExist) {
    newPraiseList.value = newPraiseList.value.filter((n) => n != num);
  } else {
    newPraiseList.value.push(num);
  }
};

// 跳转外链
const toOutsideChain = (url) => {
  if (!url) return false;
  window.location.href = "bridge:/webview/open/pageInit?url=" + url;
};

// 一起看剧弹窗显示
const seeAmovie = () => {
  prestoreId.value = -1;
  prestoreUrl.value = "";
  showPop.value = true;
  // window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
  window.location.href =
    "bridge:/webview/open/pageInit?url=https://yhkj-test.pomoho.com/";
};

// 敬请期待
const comingSoon = () => {
  showDialog({
    title: "即将开放，敬请期待",
  }).then(() => {
    // on close
  });
};

// 一起看剧确认
const confirmToVdo = () => {
  let url = encodeURIComponent("pages/Tabbar/Tabbar3?from=3");
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${url}&appId=gh_57a6136de544&pubAppId=wx392ef33f3e75f4aa`;
};

// 跳转生态专区
const toSTZQ = () => {
  window.location.href = "bridge:/baseWorker/switchTabView/pageInit?tab=1";
};

// 业务按钮跳转
const loginJump = (num, url) => {
  prestoreId.value = num;
  prestoreUrl.value = url;
  window.location.href =
    "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
};
const swiperRef = ref(null);
const initSwiper = () => {
  // 确保Swiper实例存在且DOM已更新
  if (swiperRef.value) {
    new Swiper(swiperRef.value);
  }
};
// 获取工人绑卡的卡类型及当月发薪状态
function callBackMyWorkBankSalaryState(myWorkBank, myWorkSalary) {
  typeKa.value = myWorkBank;
  monthPayroll.value = myWorkSalary;
}
onMounted(function () {
  window.callBackMyWorkBankSalaryState = callBackMyWorkBankSalaryState;
  // 获取工人绑卡的卡类型及当月发薪状态
  window.location.href = "bridge:/baseWorker/getMyWorkBankSalaryState/pageInit";
  initSwiper();
  // 登录状态
  window.callbackLoginEvent = (isLogin) => {
    // isLogin 1 已登录 0 未登录
    if (isLogin == "1") {
      if (prestoreId.value == 1) {
        window.location.href = prestoreUrl.value;
      } else if (prestoreId.value == 0) {
        router.push(prestoreUrl.value);
      }
      if (showPop.value) {
        show.value = true;
        showPop.value = false;
      }
      prestoreId.value = -1;
      prestoreUrl.value = "";
    } else {
      prestoreId.value = -1;
      prestoreUrl.value = "";
      showPop.value = false;
      // *****  调起登录页面的协议
      window.location.href =
        "bridge:/base/openLoginView/pageInit?callback=callbackLoginEvent";
    }
  };

  let wdWid = window.innerWidth;
  let rem = wdWid / 7.5;
  leftSpace.value = rem * 0.3;
  centerSpace.value = rem * 0.4;
});

// 位置
// const city = ref("南宁");
const city = ref("");
const isCitySelectionOpen = ref(0);
const setCity = (val) => (city.value = val[1].text);
const openCitySelection = () => {
  isCitySelectionOpen.value++;
};

const cutEditionBtnState = ref(true);
// console.log("router", query);
// 隐藏切换身份按钮
if (query.dataFrom) {
  // 四个平台的首字母 桂建通:gjt、桂薪宝:gxb、新薪通:xxt、云建宝:yjb、华建通:hjt
  if (
    query.dataFrom === "gjt" ||
    query.dataFrom === "gxb" ||
    query.dataFrom === "xxt" ||
    query.dataFrom === "yjb" ||
    query.dataFrom === "hjt"
  ) {
    cutEditionBtnState.value = false;
  }
}

let mxPos = false;
// 默认定位
if (query.dataFrom === "gjt" || query.dataFrom === "gxb") {
  city.value = "广西"; // 桂建通、桂薪宝，默认加载定位在广西
  mxPos = true;
} else if (query.dataFrom === "yjb") {
  city.value = "云南"; // 云建宝，默认加载定位在云南
  mxPos = true;
} else if (query.dataFrom === "xxt") {
  city.value = "新疆"; // 新薪通，默认加载定位在新疆
  mxPos = true;
}

// 城市ip定位
async function getCityByIpInit() {
  if (mxPos) {
    // 有默认定位不执行
    return;
  }
  let placeCityKey = sessionStorage.getItem("placeCityKey");
  if (placeCityKey) {
    city.value = placeCityKey;
  } else {
    let res = await getCityByIp({});
    if (res) {
      // console.log("城市ip定位", res);
      if (res.data.code === 200 ) {
        if(res.data.data === "局域网ip"){
          city.value = "南宁";
        }
        else{
          city.value = res.data.data;
          sessionStorage.setItem("placeCityKey", res.data.data);
        }
      }
    } else {
      city.value = "南宁";
    }
  }
}

onMounted(() => {
    getCityByIpInit();
});

const mapContainer = ref("mapContainer");
// 获取地址
const loadTianDiTuScript = async () => {
  const script = document.createElement("script");
  script.src = `https://api.tianditu.gov.cn/api?v=4.0&tk=e9b7bd908527d7dc3286b2d2b81fa129&type=text/javascript`;
  script.async = true;

  script.onload = () => {
    initializeMap();
    showMap.value = true;
  };
  script.onerror = () => {
    console.error("加载天地图API失败");
  };
  document.head.appendChild(script);
};

const initializeMap = async () => {
  if (window.T) {
    map = new window.T.Map(mapContainer.value, {
      center: [116.404, 39.915], // 默认坐标，实际应使用用户位置
      zoom: 15,
      layer: window.T.MapType.NORMAL_MAP,
    });
    // 获取用户本地位置
    const position = await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("浏览器不支持地理定位"));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    }).catch((error) => {
      console.error("获取位置失败:", error);
      return null;
    });

    if (position) {
      const {
        coords: { latitude, longitude },
      } = position;
      const userLocation = [longitude, latitude];
      map.centerAndZoom(userLocation, 15); // 根据用户位置调整地图中心
      // 示例：在地图上添加标记
      const marker = new window.T.Marker(userLocation).addTo(map);
    }
  } else {
    console.error("天地图API未正确加载");
  }
};

const loop = ref(false);
const autoplay = {
  delay: 3000,
  disableOnInteraction: false,
};
const oNslideChange = (swiper) => {
  // console.log('测试', swiper)
};
onMounted(() => {
  setTimeout(function () {
    loop.value = true;
  }, 100);
});

const active = ref(0);
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  padding-top: 0;
}
.topBox {
  position: relative;
  padding: 1.8rem 0.4rem 0.4rem;
  background: url(~@/assets/images/homeTopBg.jpg) top center;
  background-size: cover;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .place {
      padding: 0 0.25rem 0 0.27rem;
      font-size: 0.26rem;
      color: #fff;
      height: 0.7rem;
      line-height: 0.7rem;
      max-width: 5.2rem;
      background: url(~@/assets/images/iconLocation.png) no-repeat left center /
          0.16rem,
        url(~@/assets/images/iconDown.png) no-repeat right center / 0.12rem;
    }
    .cutEditionBtn {
      position: absolute;
      right: 0.4rem;
      top: 1rem;
      padding-left: 0.3rem;
      font-size: 0.26rem;
      height: 0.6rem;
      line-height: 0.6rem;
      color: #fff;
      background: url(~@/assets/images/iconCut.png) no-repeat left center /
        0.22rem;
    }
    .ipt {
      position: relative;
      width: 100%;
      &:before {
        position: absolute;
        left: 0.32rem;
        top: 0.27rem;
        content: "";
        display: block;
        width: 0.26rem;
        height: 0.26rem;
        background: url(~@/assets/images/iconSeek.png) no-repeat center;
        background-size: contain;
      }
      input {
        border: 0 none;
        width: 100%;
        height: 0.8rem;
        line-height: 0.6rem;
        font-size: 0.24rem;
        color: #333;
        padding: 0.1rem 0.3rem 0.1rem 0.75rem;
        background: #edf1f1;
        border-radius: 0.8rem;
      }
    }
    .messageBtn {
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      right: 0.4rem;
      background: url(~@/assets/images/iconMessage.png) no-repeat center;
      background-size: 0.46rem;
      .num {
        position: absolute;
        right: -0.12rem;
        top: 0.03rem;
        font-size: 0.22rem;
        color: #fff;
        width: 0.38rem;
        height: 0.26rem;
        line-height: 0.26rem;
        text-align: center;
        border-radius: 0.08rem;
        background: linear-gradient(to right, #fb7d22, #d75b1a);
      }
    }
  }
}
.ban {
  position: relative;
  .swiper-slide {
    position: relative;
    height: 5.8rem;
    background: #9eb3b6 url(~@/assets/images/home/ban1.jpg) no-repeat bottom
      center;
    background-size: 100%;
    &.it2 {
      background-image: url(~@/assets/images/home/ban2.png);
      background-color: #3c7ae1;
    }
    .txt {
      position: absolute;
      left: 0;
      bottom: 0.6rem;
      height: 2.8rem;
      width: 100%;
      padding: 0.6rem 0.3rem 0;
      h3 {
        font-size: 0.42rem;
        color: #1e3538;
        line-height: 0.5rem;
        font-weight: 600;
        margin-bottom: 0.24rem;
      }
      p {
        font-size: 0.2rem;
        color: #1e3538;
        line-height: 0.28rem;
      }
    }
  }
  :deep(.swiper) {
    .swiper-pagination {
      height: 0.06rem;
      line-height: 0.06rem;
      bottom: 0.34rem;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.06rem;
      background: rgba(255, 255, 255, 0.5);
      font-size: 0;
      .swiper-pagination-bullet {
        vertical-align: top;
        margin: 0;
        width: 0.44rem;
        height: 100%;
        border-radius: 0.44rem;
        opacity: 0;
        transition: all 0.3s;
        background: #fff;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.home {
  background: #f3f4f6;
  padding: 0.3rem;
  .hTil {
    font-size: 0.34rem;
    color: #43465a;
    line-height: 0.5rem;
    font-weight: 600;
    margin-bottom: 0.35rem;
  }

  .labels {
    display: flex;
    .i {
      height: 0.34rem;
      line-height: 0.34rem;
      padding: 0 0.1rem;
      font-size: 0.2rem;
      color: #007df8;
      background: #e5f2fe;
      margin-right: 0.1rem;
    }
    .e {
      font-size: 0.2rem;
      color: #2caf5a;
      height: 0.34rem;
      line-height: 0.32rem;
      padding: 0 0.1rem;
      margin-right: 0.1rem;
      border: 0.01rem solid #2caf5a;
      border-radius: 0.04rem;
    }
  }
}

.quick {
  margin-bottom: 0.5rem;
  :deep(.swiper) {
    height: 4rem;
    margin: 0 -0.3rem;
    padding: 0.2rem 0.3rem;
    .swiper-wrapper {
      justify-content: space-between;
    }
    .swiper-slide {
      position: relative;
      width: 1.06rem;
      height: 1.54rem;
      padding: 0.18rem 0 0;
      .a {
        width: 1.06rem;
        height: 1.54rem;
      }
      .dt {
        width: 0.88rem;
        height: 0.88rem;
        margin: 0 auto 0.15rem;
        img {
          border-radius: 0.3rem;
          box-shadow: 0 0.06rem 0.5rem rgba(0, 0, 0, 0.06);
        }
      }
      .dd {
        font-size: 0.26rem;
        color: #43465a;
        line-height: 0.32rem;
        text-align: center;
        white-space: nowrap;
      }
      .note {
        position: absolute;
        left: 0;
        right: 0;
        top: -0.08rem;
        font-size: 0.2rem;
        margin: 0 auto;
        color: #fff;
        width: 0.8rem;
        text-align: center;
        height: 0.42rem;
        line-height: 0.42rem;
        border-radius: 0.12rem;
        background: #007df8;
      }
    }
    .swiper-pagination {
      height: 0.06rem;
      line-height: 0.06rem;
      bottom: 0;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      background: #d8d9db;
      font-size: 0;
      border-radius: 0.06rem;
      .swiper-pagination-bullet {
        vertical-align: top;
        margin: 0;
        width: 0.44rem;
        height: 100%;
        border-radius: 0.44rem;
        opacity: 0;
        transition: all 0.3s;
        background: #007df8;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.recommend {
  overflow: hidden;
  margin-bottom: 0.8rem;
  .box {
    position: relative;
    float: left;
    background: #fff;
    overflow: hidden;
    border-radius: 0.2rem;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    h4 {
      font-size: 0.28rem;
      color: #434659;
      line-height: 0.36rem;
      font-weight: 600;
    }
    p {
      font-size: 0.22rem;
      color: #848ba1;
      line-height: 0.32rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .b1 {
    width: 3.35rem;
    height: 4.72rem;
    margin-right: 0.2rem;
    .dt {
      height: 3.66rem;
      padding: 0.3rem 0.24rem;
      background: url(~@/assets/images/home/quickPic1.jpg) no-repeat center;
      background-size: cover;
      h4 {
        margin-bottom: 0.16rem;
      }
      line-height: 0.36rem;
    }
    .dd {
      padding: 0.2rem 0.3rem;
      background: #fff;
      h4 {
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.3rem;
        font-weight: 600;
        margin-bottom: 0.06rem;
      }
      .price {
        display: flex;
        font-size: 0.2rem;
        color: #e62a2b;
        line-height: 0.3rem;
        align-items: flex-end;
        vertical-align: bottom;
        .i {
          font-size: 0.24rem;
          font-family: "DIN-Bold";
        }
        .num {
          font-size: 0.34rem;
          font-family: "DIN-Bold";
        }
      }
    }
  }
  .b2 {
    width: 3.35rem;
    height: 2.26rem;
    padding: 0.3rem 0.24rem;
    margin-bottom: 0.2rem;
    background: url(~@/assets/images/home/recommendBg2.jpg) no-repeat center;
    background-size: cover;
    .pic {
      position: absolute;
      width: 1.2rem;
      height: 1rem;
      right: 0.2rem;
      bottom: 0.2rem;
    }
    .d {
      margin-top: 0.4rem;
      width: 0.9rem;
      height: 0.44rem;
      border-radius: 0.44rem;
      line-height: 0.42rem;
      text-align: center;
      border: 0.01rem solid #4f7ba3;
      font-size: 0.2rem;
      color: #4f7ba3;
      em {
        padding-right: 0.13rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALCAYAAABcUvyWAAAAsUlEQVQYlU3PMWoCURSF4c9kAeIC0oRsII2Qzsoija8JaJgNmCIbcMrZgWVSBBxImuADsbGxsrdwCzZWKQJJERh54oT54Vb/vedwDSazj8FkNq6qSnMu8INpyMuRBkk8YY63kJf3tWuls5CXHSxwi34sss1JJEJeXiEiLYV/cZbX2GCfOprcoY11M+oB73jBc13ewxIrjGKR/V5u/266+MQOw1hk3/UfrzjgMRbZ1ykXR21BReCFt60uAAAAAElFTkSuQmCC)
          no-repeat right center;
        background-size: 0.06rem;
      }
    }
  }
  .b3,
  .b4 {
    width: 1.57rem;
    height: 2.26rem;
    padding: 0.18rem 0.2rem;
    background: url(~@/assets/images/home/recommendBg3.jpg) no-repeat center;
    background-size: cover;
    h4 {
      font-size: 0.24rem;
      line-height: 0.3rem;
      text-align: center;
    }
    .pic {
      margin-top: 0.2rem;
      height: 1.12rem;
      img {
        margin: 0 auto;
      }
    }
  }
  .b4 {
    float: right;
    background-image: url(~@/assets/images/home/recommendBg4.jpg);
  }
}
.ecology {
  margin-bottom: 0.6rem;
  .ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem;
    height: 1.54rem;
    width: 3.35rem;
    margin-bottom: 0.2rem;
    border-radius: 0.2rem;
    background: #fff;
    .dt {
      width: 0.78rem;
    }
    .dd {
      width: 2rem;
      h4 {
        font-size: 0.26rem;
        line-height: 0.36rem;
        color: #3c3f50;
        font-weight: 600;
      }
      p {
        font-size: 0.22rem;
        color: #5b5d6a;
        line-height: 0.3rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.newMessage {
  margin-bottom: 0.5rem;
  .li {
    padding: 0.26rem 0.2rem 0.16rem;
    margin-bottom: 0.3rem;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.03);
    .dt {
      padding-bottom: 0.2rem;
    }
    .tilWr {
      display: flex;
      margin-bottom: 0.2rem;
      .via {
        width: 0.72rem;
        height: 0.72rem;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 0.2rem;
      }
      .ri {
        flex: 1;
        h4 {
          font-size: 0.28rem;
          color: #3c3f50;
          line-height: 0.36rem;
          font-weight: 600;
        }
        .time {
          font-size: 0.22rem;
          color: #5b5d6a;
          line-height: 0.36rem;
        }
      }
    }
    .pBox {
      p {
        font-size: 0.24rem;
        color: #5b5d6a;
        line-height: 0.36rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .dd {
      border-top: 0.01rem solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.1rem;
      .operate {
        display: flex;
      }
      .btn {
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.24rem;
        color: #3c3f50;
        padding-left: 0.42rem;
        background: url(~@/assets/images/iconReply.png) no-repeat left center;
        font-family: "DIN-Bold";
        background-size: 0.3rem;
        margin-left: 0.4rem;
        min-width: 0.8rem;
        &:first-child {
          margin-left: 0;
        }
      }
      .praise {
        padding-left: 0.48rem;
        background-image: url(~@/assets/images/iconPraise.png);
        background-size: 0.35rem;
        &.on {
          background-image: url(~@/assets/images/iconPraiseOn.png);
        }
      }
    }
  }
}
.hotJobs {
  position: relative;
  margin-bottom: 0.5rem;
  .moreBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-right: 0.2rem;
    font-size: 0.22rem;
    color: #5b5d6a;
    background: url(~@/assets/images/iconMore.png) no-repeat right center;
    background-size: 0.1rem;
  }
  .li {
    padding: 0.3rem 0.2rem 0.2rem;
    margin-bottom: 0.3rem;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.24rem rgba(0, 0, 0, 0.04);
    .dt {
      position: relative;
      padding-bottom: 0.3rem;
    }
    h4 {
      font-size: 0.28rem;
      color: #3c3f50;
      line-height: 0.36rem;
      font-weight: 600;
    }
    p {
      font-size: 0.24rem;
      color: #5b5d6a;
      line-height: 0.36rem;
      margin-bottom: 0.2rem;
    }
    .time {
      position: absolute;
      right: 0;
      bottom: 0.3rem;
      font-size: 0.2rem;
      color: #999;
      line-height: 0.34rem;
    }
    .dd {
      border-top: 0.01rem solid #eee;
      display: flex;
      justify-content: space-between;
      padding-top: 0.24rem;
      .le {
        display: flex;
        align-items: center;
        .via {
          width: 0.58rem;
          height: 0.58rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 0.16rem;
        }
      }
      .contactBtn {
        height: 0.58rem;
        line-height: 0.58rem;
        font-size: 0.22rem;
        border-radius: 0.58rem;
        color: #fff;
        padding: 0 0.2rem 0 0.6rem;
        background: #007df8 url(~@/assets/images/iconConsult.png) no-repeat
          0.2rem center;
        background-size: 0.3rem;
      }
    }
  }
}
.videoList {
  position: relative;
  .moreBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-right: 0.2rem;
    font-size: 0.22rem;
    color: #5b5d6a;
    background: url(~@/assets/images/iconMore.png) no-repeat right center;
    background-size: 0.1rem;
  }
  .li {
    margin-bottom: 0.3rem;
    overflow: hidden;
    border-radius: 0.2rem;
    background: #fff;
    .dt {
      position: relative;
      min-height: 3rem;
      background: #d8d9db;
      .btn {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0.7rem;
        height: 0.7rem;
        margin: -0.35rem 0 0 -0.35rem;
        border-radius: 50%;
        background: url(~@/assets/images/iconPlay.png) no-repeat center;
        background-size: contain;
        box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
      }
    }
    .dd {
      height: 0.9rem;
      padding: 0.2rem;
      h4 {
        font-size: 0.28rem;
        color: #43465a;
        line-height: 0.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
.videoHint {
  .cnt {
    padding: 0.5rem;
    text-align: center;
    .til {
      font-size: 0.3rem;
      line-height: 0.46rem;
      color: #43465a;
    }
    .p {
      font-size: 0.28rem;
      line-height: 0.4rem;
      color: #aaa;
      margin-top: 0.4rem;
    }
  }
}
.place {
  position: absolute;
  left: 0.4rem;
  top: 1rem;
  height: 0.6rem;
  line-height: 0.6rem;
  font-size: 0.28rem;
  color: #fff;
  padding: 0 0.28rem 0 0.4rem;
  background: url(~@/assets/images/iconPlace.png) no-repeat left center /
      0.23rem,
    url(~@/assets/images/iconDown.png) no-repeat right center / 0.14rem;
}

.my-swipe {
  overflow: hidden;
  border-radius: 0.28rem;
  margin-bottom: 0.4rem;
  .van-swipe-item {
    position: relative;
    .txt {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 1.2rem 0.5rem 0;
    }
    h2 {
      font-size: 0.36rem;
      line-height: 0.44rem;
      color: #ffbb00;
      margin-bottom: 0.16rem;
      letter-spacing: 0.1rem;
      font-weight: 600;
    }
    h3 {
      font-size: 0.22rem;
      color: #b0c1ca;
      line-height: 1;
      font-family: "Gilroy-Bold";
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .it2 {
    h2 {
      background: linear-gradient(to left bottom, #bbf1f9, #22e5a4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0.1rem;
    }
    h4 {
      font-size: 0.24rem;
      color: #a1c5c2;
      line-height: 0.4rem;
    }
  }
}

.banner {
  overflow: hidden;
  border-radius: 0.2rem;
  :deep(.swiper-pagination) {
    font-size: 0;
    line-height: 0;
    height: 0.06rem;
    bottom: 0.3rem;
    border-radius: 0.06rem;
    left: 50%;
    width: auto;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.5);
    .swiper-pagination-bullet {
      opacity: 0;
      margin: 0;
      width: 0.44rem;
      height: 100%;
      border-radius: 0.06rem;
      background: #fff;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

.projectColumn {
  margin-top: 0.6rem;
  :deep(.van-tabs__wrap) {
    height: 0.8rem;
    .van-tabs__nav {
      background: transparent;
    }
    .van-tab {
      padding: 0;
      font-size: 0.28rem;
      color: #5b5d6a;
    }
    .van-tab--active {
      font-size: 0.34rem;
      color: #007df8;
    }
    .van-tabs__line {
      width: 0.7rem !important;
      height: 0.06rem;
      background: linear-gradient(to right, transparent, #047ef8) !important;
      border-radius: 0.06rem;
    }
  }
}
.projectList {
  margin-top: 0.4rem;
  .sty1 {
    .li {
      padding: 0.25rem 0.22rem;
      background: #fff;
      overflow: hidden;
      border-radius: 0.2rem;
      box-shadow: 0 0 0.24rem rgba(0, 0, 0, 0.04);
      h3 {
        font-size: 0.28rem;
        color: #3c3f50;
        line-height: 0.4rem;
      }
      p {
        margin-top: 0.04rem;
        font-size: 0.24rem;
        color: #5b5d6a;
        line-height: 0.4rem;
      }
      .hint {
        margin-top: 0.2rem;
        padding: 0.1rem 0.2rem;
        font-size: 0.2rem;
        color: #e99c52;
        line-height: 0.26rem;
        border-radius: 0.04rem;
        background: #fcf3eb;
        margin-top: 0.3rem;
      }
      .bom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.4rem;
        .source {
          font-size: 0.24rem;
          color: #5b5d6a;
          line-height: 0.4rem;
          em {
            color: #3c3f50;
          }
        }
        .num {
          display: flex;
          align-items: center;
          font-size: 0.22rem;
          color: #007df8;
          em {
            font-size: 0.5rem;
            font-weight: 600;
          }
          span {
            font-size: 0.3rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  .sty2 {
    .li {
      position: relative;
      padding: 0.29rem 0.22rem;
      background: #fff;
      overflow: hidden;
      border-radius: 0.2rem;
      box-shadow: 0 0 0.24rem rgba(0, 0, 0, 0.04);
      margin-bottom: 0.3rem;
      &:before {
        position: absolute;
        left: 0;
        top: 0.35rem;
        content: "";
        display: block;
        width: 0.04rem;
        height: 0.22rem;
        background: #007df8;
      }
      h3 {
        font-size: 0.28rem;
        color: #3c3f50;
        line-height: 0.4rem;
        margin-right: 1.2rem;
      }
      .price {
        font-size: 0.2rem;
        color: #007df8;
        line-height: 0.4rem;
        position: absolute;
        right: 0.22rem;
        top: 0.3rem;
        em {
          font-size: 0.34rem;
          font-weight: 600;
        }
      }
      p {
        font-size: 0.24rem;
        color: #5b5d6a;
        line-height: 0.36rem;
        margin-top: 0.08rem;
      }
      .label {
        display: flex;
        margin: 0.12rem 0;
        .i {
          font-size: 0.18rem;
          color: #ee783f;
          background: #fdf1eb;
          border-radius: 0.04rem;
          height: 0.32rem;
          line-height: 0.32rem;
          padding: 0 0.1rem;
          margin: 0 0.06rem 0.06rem 0;
        }
        .i2 {
          color: #10ac6d;
          background: #e7f7f0;
        }
      }
      .bom {
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-between;
        padding-top: 0.2rem;
        border-top: 1px solid #eee;
        .time {
          font-size: 0.2rem;
          color: #999;
          line-height: 0.4rem;
        }
        .site {
          border: 0.01rem solid #99cbfc;
          border-radius: 0.04rem;
          font-size: 0.2rem;
          color: #007df8;
          line-height: 0.38rem;
          padding: 0 0.1rem;
        }
      }
    }
  }
  .sty3 {
    padding-bottom: 0.34rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .li {
      width: 3.22rem;
      border-radius: 0.1rem;
      background: #f8f8f8;
      margin-bottom: 0.26rem;
      overflow: hidden;
      .pic {
        height: 2rem;
        background: no-repeat center;
        background-size: cover;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .txt {
        padding: 0.18rem 0.2rem;
        display: flex;
        height: calc(100% - 2rem);
        flex-direction: column;
        justify-content: space-between;
        h4 {
          font-size: 0.26rem;
          color: #333;
          line-height: 0.3rem;
          margin-bottom: 0.18rem;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; /* 在最后一行添加省略号 */
        }
        .price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .num {
            font-size: 0.29rem;
            color: #e62a2b;
            font-family: "Gilroy-Bold", "微软雅黑";
            em {
              font-size: 0.24rem;
            }
          }
          .label {
            // height: .3rem;
            line-height: 0.3rem;
            font-size: 0.2rem;
            color: #fb8741;
            background: #fee7d9;
            padding: 0 0.08rem;
            border-radius: 0.04rem;
            max-width: 70%;
          }
        }
      }
    }
  }
  .sty4 {
    .li {
      margin-bottom: 0.3rem;
      overflow: hidden;
      border-radius: 0.2rem;
      background: #fff;
      .dt {
        position: relative;
        min-height: 3rem;
        background: #d8d9db;
        .btn {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 0.7rem;
          height: 0.7rem;
          margin: -0.35rem 0 0 -0.35rem;
          border-radius: 50%;
          background: url(~@/assets/images/iconPlay.png) no-repeat center;
          background-size: contain;
          box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
        }
      }
      .dd {
        height: 0.9rem;
        padding: 0.2rem;
        h4 {
          font-size: 0.28rem;
          color: #43465a;
          line-height: 0.5rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
.lendSwiper {
  width: 7.5rem;
  margin: 0 -0.3rem;
  padding: 0 0.3rem;
}
.lend {
  margin-top: 0.6rem;
  .swiper-slide {
    width: 5.92rem;
  }
}
.commodity {
  margin-top: 0.6rem;
}
</style>

